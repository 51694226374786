import React from 'react';
// import scrollAnimation from "../../utils/scrollAnimation";
import logo from '../assets/images/logo-300x70.webp';
import { Link } from 'react-router-dom';
export default function Footer({ PhoneNumber }) {
  // const cardRefs = [useRef(), useRef(), useRef()];

  // useEffect(() => {
  //   cardRefs.forEach((ref) => {
  //     if (ref.current) {
  //       scrollAnimation.observeElement(ref.current);
  //     }
  //   });
  // }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, '');
  };
  // useEffect (()=>{

  // }, [cardRefs])
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div>
      <div className="Footer">
        <div className="Footer1">
          {' '}
          <div className="f-logo">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="f-pages">
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>
          <div className="f-contact">
            <h4>Contact</h4>
            <ul>
              <li>
                <a href="mailto:info@firstratebankruptcyattorneys.com">
                  info@firstratebankruptcyattorneys.com
                </a>{' '}
              </li>
              <li>
                <a href={`tel:${telPhoneNumber}`}> {PhoneNumber.PhoneNo}</a>{' '}
              </li>
            </ul>
          </div>{' '}
        </div>
        <div
          className="legal-disclaimer"
          style={{
            fontSize: '12px',
            color: '#666',
            padding: '20px',
            textAlign: 'justify',
          }}>
          firstratebankruptcyattorneys.com (the “Site”) is not a law firm and
          not a lawyer referral service; nor is it a substitute for hiring an
          attorney or law firm. Any information displayed or provided on the
          Site is for personal use only. This Site offers no legal, business, or
          tax advice, recommendations, mediation or counseling in connection
          with any legal matter, under any circumstances, and nothing we do and
          no element of the Site or the Site’s call connect functionality ("Call
          Service") should be construed as such. Some of the attorneys, law
          firms and legal service providers (collectively, "Third Party Legal
          Professionals") are accessible via the Call Service by virtue of their
          payment of a fee to promote their respective services to users of the
          Call Service and should be considered as advertising. This Site does
          not endorse or recommend any participating Third-Party Legal
          Professionals. Your use of the Site or Call Service is not intended to
          create, and any information submitted to the Site and/or any
          electronic or other communication sent to the Site will not create a
          contract for representation or an attorney-client relationship between
          you and these Site or any of the Third Party Legal Professionals.
        </div>
        <span>
          © Copyright 2024 First Rate Bankruptcy Attorney. All Rights Reserved.
        </span>
      </div>
    </div>
  );
}
